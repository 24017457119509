<template>
    <div class="guide">
        <b-container>
            <b-row>
                <b-col>
                    <b-card class="text-center d-flex flex-column justify-content-center">
                        <b-card-title class="m-0">{{ $t("Video Guide") }}</b-card-title>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
        <b-container>
       <b-row>
        <b-col md="6" v-for="(item, index) in video" :key="index">
            <b-card class="d-flex justify-content-center text-center">
                <h3>
                    {{ item.title }}
                </h3>
                <iframe width="560" height="315" :src="item.url" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </b-card>
        </b-col>
       </b-row>
    </b-container>
    </div>
</template>
<script>
import {BCard, BCardBody, BCardText, BCardTitle, BCol, BContainer, BRow} from 'bootstrap-vue'
export default {
    name: 'Guide',
    components: {
        BCard,
        BCardBody,
        BCardText,
        BCardTitle,
        BCol,
        BContainer,
        BRow
    },
    data() {
        return {
            video: [
                {
                    title: 'Hızlı Tur',
                    url: 'https://www.youtube.com/embed/E8Yon6nNVno?si=OqdL3OG7xJpwi9gr'
                },
                {
                    title: 'Anasayfa',
                    url: 'https://www.youtube.com/embed/nELCsFH6dA8?si=bVyfjNowo4vieN-I'
                },
                {
                    title: 'Takvim',
                    url: 'https://www.youtube.com/embed/jHbFRk64gdY?si=GAnE7eeHaY3Z6xTD'
                },
                {
                    title: 'Danışanlar',
                    url: 'https://www.youtube.com/embed/C4JYduNsLSs?si=ILT5SKR699oCI4zG'
                },
                {
                    title: 'Danışan Ekle',
                    url: 'https://www.youtube.com/embed/RPNxORM2a7I?si=0aw9yP4FuO_3_oZ3'
                },
                {
                    title: 'Danışan Sayfası',
                    url: 'https://www.youtube.com/embed/D-DDuQ6B5-0?si=IFPHukmM88dP9SQQ'
                },
                {
                    title: 'GBT Uygula',
                    url: 'https://www.youtube.com/embed/gtxo1TfTmRY?si=kpTlQtB3WTphTiwD'
                },
                {
                    title: 'GBT Sonuçlar',
                    url: 'https://www.youtube.com/embed/0tWXghF7-eM?si=nb543-lXuOL_mtP0'
                },
                {
                    title: 'GBT Dikkat Bölümü',
                    url: 'https://www.youtube.com/embed/f36VwrmoxQw?si=VA04Q7YnNXakfjnT'
                },
                {
                    title: 'GBT Mantık Bölümü',
                    url: 'https://www.youtube.com/embed/OZIUMSOniCE?si=Wy0OOO0sS52NmRq4'
                },
                {
                    title:'GBT Konsantrasyon Bölümü',
                    url:'https://www.youtube.com/embed/CXyop9x9UBU?si=6H_d_6h05g8VcT-3'
                },
                {
                    title:'GBT Hafıza Bölümü',
                    url:'https://www.youtube.com/embed/dScpsbukOi0?si=O9bwb6VRV9r3ymAb'
                },
                {
                    title:'GBT Set İşlemleri',
                    url:'https://www.youtube.com/embed/dGl8vG3rBX8?si=XYtDu4aqSOUvJeqi'
                },
                {
                    title:'Hesap Ayarları',
                    url:'https://www.youtube.com/embed/Q5kglitPgiw?si=P_zTwO0ED7qZgeba'
                },
                {
                    title:'Kişisel Bilgileriniz',
                    url:'https://www.youtube.com/embed/UcRXTAO9y2I?si=wJY-XjmsFgmEa8tR'
                },
                {
                    title:'Referans',
                    url:'https://www.youtube.com/embed/Y3CgBhbNXKs?si=nyeB6KkHwHIdMb-H'
                },
                {
                    title:'Şifre Değiştirme',
                    url:'https://www.youtube.com/embed/EkURbffSKuc?si=srXgHBHZmUiRnH-n'
                },
                {
                    title:'Bağlı Cihazlar',
                    url:'https://www.youtube.com/embed/--c78zTEzLA?si=b0PBzi7OLW5YwrlL'
                },
                {
                    title:'Kurumsal Bilgiler',
                    url:'https://www.youtube.com/embed/VbBG6v29UPI?si=wQFgpkmh6XOTColg'
                },
                {
                    title:'Paketim',
                    url:'https://www.youtube.com/embed/puj-IeEOO8o?si=t-FTMXlmj4KeuWwx'
                },
                {
                    title:'Satın Aldıklarım',
                    url:'https://www.youtube.com/embed/3mFoLQmX9x0?si=IUZM6C-J5cRR1p23'
                },
                {
                    title:'Bilgi Bankası',
                    url:'https://www.youtube.com/embed/Royk33ulQ1A?si=i-8kpMUmDw-KtaeF'
                },
                {
                    title:'Formlar',
                    url:'https://www.youtube.com/embed/l_7BsMExB14?si=wNlCb-4rXvyj07Ap'
                },
                {
                    title:'Üyelik Başvurusu',
                    url:'https://www.youtube.com/embed/1d_bbRn3YXw?si=kVuZ5u71gHuT7TO1'
                },
                {
                    title:'Veli Takip Sistemi',
                    url:'https://www.youtube.com/embed/8x0B1-m_aQI?si=T-cJ5O11Sjy-GjgH'
                }
            ]
        }
    }
}

/* 
<iframe width="560" height="315" src="https://www.youtube.com/embed/zZpiKqKkWYU?si=UlikBqowcWD9AcAD" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
<iframe width="560" height="315" src="https://www.youtube.com/embed/-1nwySP6fUo?si=jgVJkf7rxQczOFwM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
<iframe width="560" height="315" src="https://www.youtube.com/embed/0trrrioU1vo?si=PORL2OxG_L7wD_76" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
<iframe width="560" height="315" src="https://www.youtube.com/embed/VaX4RVYm4rM?si=p8flZIOK8cVoQZXg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
<iframe width="560" height="315" src="https://www.youtube.com/embed/DoRQMWu0hWk?si=qha9h3-H6qwVYHOY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
<iframe width="560" height="315" src="https://www.youtube.com/embed/bAf8n98KtUg?si=Qu_gZsVv6_P2KFAB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
<iframe width="560" height="315" src="https://www.youtube.com/embed/EVbDhLBUg7g?si=4qyRPRZAATciabU_" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
<iframe width="560" height="315" src="https://www.youtube.com/embed/20q6IpoNLlc?si=LT3T0oIAxGtwHDyG" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
<iframe width="560" height="315" src="https://www.youtube.com/embed/VSjPVZVCAmU?si=Yrq5CURIopFBsU8o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
<iframe width="560" height="315" src="https://www.youtube.com/embed/Ka47HKz7398?si=yaB2hIuUGXKnm1v5" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
<iframe width="560" height="315" src="https://www.youtube.com/embed/J3YBsg6FWTI?si=XkCbzE2lQ_QMMYFi" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
<iframe width="560" height="315" src="https://www.youtube.com/embed/qb2OOVO5kEo?si=H81kBsQNOVYxW8mh" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
<iframe width="560" height="315" src="https://www.youtube.com/embed/nBMlHXps0ms?si=hfCKTF4_tfIXGVMd" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
<iframe width="560" height="315" src="https://www.youtube.com/embed/XEttfz_bkHc?si=hjSHGCLyOFBws2P6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
<iframe width="560" height="315" src="https://www.youtube.com/embed/UyD_1hqQGb4?si=K_U6zVLod2Xf3iy1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
<iframe width="560" height="315" src="https://www.youtube.com/embed/vxSYWmDGM0s?si=U_nZSJwUW2ZcVqXH" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
<iframe width="560" height="315" src="https://www.youtube.com/embed/ilyP2bw-7kk?si=IoNhC3OiKcfaS5Yf" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
<iframe width="560" height="315" src="https://www.youtube.com/embed/yAMmNjB7wiQ?si=bF6OPbetW0JmymXV" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
<iframe width="560" height="315" src="https://www.youtube.com/embed/uB3uDLBAd4Q?si=7xX3LXz5KxSL1U3U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
<iframe width="560" height="315" src="https://www.youtube.com/embed/yizAQpyR8LI?si=mCWAKnhvCqNHOs2t" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
<iframe width="560" height="315" src="https://www.youtube.com/embed/IpxknaHjuSQ?si=C5YQZD3967_y9PLz" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
<iframe width="560" height="315" src="https://www.youtube.com/embed/CmSo0-klUS4?si=OoeFMNjicThYKdIJ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
*/
</script>
<style scoped>
</style>